export const filterReportParams = (params, user) => {
  const result = { ...params };
  if (!(user.is_admin || user.is_company_owner || user.is_tenant_owner)) {
    if (result.valueColumns) {
      result.valueColumns = result.valueColumns.filter((item) => item.value !== "payout_fee");
    }
    if (result.exportColumns) {
      result.exportColumns = result.exportColumns.filter((item) => item.field !== "payout_fee");
    }
  }
  return result;
};
